import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ogImage404 from "../images/og-image/og-image-404.png"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <SEO
        title="404: Not found - Cuuno"
        description="404 : Not found"
        image={ogImage404}
        defaultTitle="404 - Cuuno"
        titleTemplate="404"
      />
    <div className="cb-container">
      <div className="cb-herosection">
        <div className="cb-herosection-wrap">
          <div class="cb-header cb-404-header">
            <h1>404</h1>
          </div>
          <div class="cb-header cb-404-subheader">
            <h1>Wir entschuldigen uns aufrichtigs.</h1>
          </div>

          <div class="cb-text cb-404-text">
            <p>
              Die Seite, die Sie suchen, ist nicht mehr hier. Vielleicht war sie
              von Anfang an nicht hier. Auf jeden Fall bedauern wir, dass Sie
              auf diese sinnlose Suche geschickt wurden und haben bereits
              Schritte unternommen, um die verantwortliche Person zu entlassen.
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default NotFoundPage
